import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const ContactsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Contacto" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h4>
            Llama <a href="tel:+525554166937">55 5416 6937</a>
          </h4>
          <h4>
            Escribe a <a href="mailto:hola@dacs.mx">hola@dacs.mx</a>
          </h4>
          <h4 id="form">O deja un mensaje</h4>
          <h4>Con gusto comenzamos a apoyarte con cualquiera de nuestros servicios.</h4>
          <form
            method="post"
            name="contactPageDACS"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contactPageDACS" />
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="name" id="demo-name" placeholder="Tu nombre" />
              </div>
              <div className="col-6 col-12-xsmall">
                <input type="email" name="email" id="demo-email" placeholder="ejemplo@dacs.mx" />
              </div>

              {/* <div className="col-6 col-12-small">
                <input type="checkbox" id="demo-copy" name="demo-copy" />
                <label htmlFor="demo-copy">Envíame una copia</label>
              </div>
              <div className="col-6 col-12-small">
                <input type="checkbox" id="demo-human" name="demo-human" />
                <label htmlFor="demo-human">Soy humano</label>
              </div> */}
              {/* Break */}
              <div className="col-12">
                <textarea
                  name="message"
                  id="demo-message"
                  placeholder="Tu mensaje"
                  rows={6}
                  defaultValue={""}
                />
              </div>
              {/* Break */}
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      // defaultValue="Send Message"
                      className="primary"
                      value="Enviar"
                    />
                  </li>
                  {/* <li>
                    <input type="reset" value="Reiniciar" />
                  </li> */}
                </ul>
              </div>
            </div>
          </form>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  smallPic: file(relativePath: {eq: "fabio-comparelli-696506-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  medPic: file(relativePath: {eq: "sophia-valkova-30139-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  largePic: file(relativePath: {eq: "vladimir-malyutin-98174-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => <ContactsPage location={props.location} data={data} {...props} />}
  />
);
